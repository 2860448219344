import type { NextPage } from 'next'
import Head from 'next/head'
import Image from 'next/image'
import styles from './Home.module.css'

const Home: NextPage = () => {
    return (
        <div className={styles.container}>
            <Head>
                <title>Loupe Partner Tools</title>
                <meta name="description" content="Tooling to support Loupe partnered shops" />
                <link rel="icon" href="/favicon.png" />
            </Head>

            <main className={styles.main}>
                <h1 className={styles.title}>
                    Welcome to <a href="#">Loupe!</a>
                </h1>

                <p className={styles.description}>
                    Get started by...
                </p>

                <div className={styles.grid}>
                    <a href="#" className={styles.card}>
                        <h2>Streams &rarr;</h2>
                        <p>View all streams</p>
                    </a>

                    <a href="#" className={styles.card}>
                        <h2>Orders &rarr;</h2>
                        <p>View all orders</p>
                    </a>

                    <a href="#" className={styles.card}>
                        <h2>Followers &rarr;</h2>
                        <p>View all followers</p>
                    </a>

                    <a href="#" className={styles.card}>
                        <h2>Reports &rarr;</h2>
                        <p>View and generate reports</p>
                    </a>
                </div>
            </main>

            <footer className={styles.footer}>
                <a
                    href="https://loupetheapp.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Built with &hearts; by the Loupe Engineering Team
                </a>
            </footer>
        </div>
    )
}

export default Home
